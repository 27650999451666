<template>
    <base-table models-name="citta"
                :is-loading="isLoading"
                :headers="headers"
                @changeOptions="onChangeOptions($event)"
    >
        <template #header="{ onChangeFilter }">
            <citta-table-header @changeOptions="onChangeOptions($event)" @changeFilter="onChangeFilter"></citta-table-header>
        </template>

        <template #REGIONE="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <i v-on="on">{{ item.REGIONE }}</i>
                </template>
                <span>{{ item.RIPARTIZIONE_GEOGRAFICA }}</span>
            </v-tooltip>
        </template>

        <template #DENOMINAZIONE="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <i v-on="on">{{ getTesto(item).DENOMINAZIONE }} (<strong>{{ getTesto(item).LNG_SIGLA }}</strong>)</i>
                </template>
                <span>{{ item.LATITUDINE }}, {{ item.LONGITUDINE }}</span>
            </v-tooltip>
        </template>

        <template #PROVINCIA="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <i v-on="on">{{ item.SIGLA }}</i>
                </template>
                <span>{{ item.PROVINCIA }}</span>
            </v-tooltip>
        </template>

        <template #EV_COUNT="{ item }">
            <button-tooltip fab
                            x-small
                            :text="''+item.EV_COUNT"
                            color="primary"
                            :to="'/secure/eventi?ctt=' + item.ID_REC + '&nmCtt=' + item.DENOMINAZIONE + ' ' + item.SIGLA"
                            exact
                            :tooltip="item.DENOMINAZIONE + ' possiede ' + item.EV_COUNT + ' eventi associati'"
            ></button-tooltip>
        </template>

    </base-table>
</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import CittaTableHeader from '@/components/secure/citta/components/CittaTableHeader';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'CittaTable',
        components: {ButtonTooltip, CittaTableHeader, BaseTable},
        props: {isLoading: Boolean, idLinguaFk: Number},
        data: (_this) => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: _this.$store.getters.CITTA_REGION + ' ',
                    value: 'REGIONE',
                    align: 'center',
                    custom: true
                },
                {
                    text: _this.$store.getters.CITTA_NAME + ' ',
                    value: 'DENOMINAZIONE',
                    align: 'center',
                    sortable: false,
                    custom: true
                },
                {
                    text: _this.$store.getters.CITTA_PROVINCE + ' ',
                    value: 'PROVINCIA',
                    align: 'center',
                    custom: true
                },
                {
                    text: _this.$store.getters.MENU_EVENTS + ' ',
                    value: 'EV_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: _this.$store.getters.DEFAULT_VIEWS + ' ',
                    value: 'VISUALIZZAZIONI',
                    align: 'center'
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getTesto(citta) {

                let testo = citta.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = citta.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = citta.testi[0];
                }

                return testo;

            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <base-sezione models-name="citta" :sezione-name="$store.getters.MENU_CITY" no-padding>

        <template #table="{ isLoading, onChangeOptions }">

            <loading-resouces v-if="isLingueLoading"
                              icon="fa-flag"
                              messaggio="Recupero delle lingue in corso"
            ></loading-resouces>

            <citta-table v-else-if="Boolean(idLinguaFk)"
                         :is-loading="isLoading"
                         :id-lingua-fk="idLinguaFk"
                         @changeOptions="onChangeOptions"
            ></citta-table>

            <no-data-available v-else models-name="lingue" type="lingue"></no-data-available>

        </template>

        <template #header>

            <v-spacer></v-spacer>
            <h-col cols="12" sm="auto">
                <base-select-lingue v-model="idLinguaFk" @finish="isLingueLoading = false"></base-select-lingue>
            </h-col>

        </template>

    </base-sezione>
</template>

<script>
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSezione from '@/components/aaaProject/commons/BaseSezione';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import CittaTable from '@/components/secure/citta/components/CittaTable';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import BaseSelectLingue from '@/components/aaaProject/table/api/BaseSelectLingue';

    export default {
        name: 'Citta',
        components: {HCol, BaseSelectLingue, NoDataAvailable, LoadingResouces, CittaTable, BaseSezione},
        data: () => ({
            isLingueLoading: true,
            idLinguaFk: undefined
        })
    }
</script>

<style scoped>

</style>

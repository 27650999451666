var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"citta","is-loading":_vm.isLoading,"headers":_vm.headers},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('citta-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"REGIONE",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({},on),[_vm._v(_vm._s(item.REGIONE))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.RIPARTIZIONE_GEOGRAFICA))])])]}},{key:"DENOMINAZIONE",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({},on),[_vm._v(_vm._s(_vm.getTesto(item).DENOMINAZIONE)+" ("),_c('strong',[_vm._v(_vm._s(_vm.getTesto(item).LNG_SIGLA))]),_vm._v(")")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.LATITUDINE)+", "+_vm._s(item.LONGITUDINE))])])]}},{key:"PROVINCIA",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({},on),[_vm._v(_vm._s(item.SIGLA))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.PROVINCIA))])])]}},{key:"EV_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","x-small":"","text":''+item.EV_COUNT,"color":"primary","to":'/secure/eventi?ctt=' + item.ID_REC + '&nmCtt=' + item.DENOMINAZIONE + ' ' + item.SIGLA,"exact":"","tooltip":item.DENOMINAZIONE + ' possiede ' + item.EV_COUNT + ' eventi associati'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
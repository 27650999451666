<template>
    <h-row>
        <h-col cols="12">
            <base-table-header-filter exact
                                      @changeFilter="$emit('changeFilter', $event)"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-filter>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTableHeaderFilter from '@/components/aaaProject/table/BaseTableHeaderFilter';

    export default {
        name: 'CittaTableHeader',
        components: {HRow, HCol, BaseTableHeaderFilter}
    }
</script>

<style scoped>

</style>
